/* eslint-disable camelcase */
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// ----------------------------------------------------------------------

const accessToken = localStorage.getItem('@Famires:token');

// THUNKS
export const fetchPedidosStatus = createAsyncThunk(
  'pedidos/fetchAllStatus',
  async ({ _estabelecimento_id, entregue }) => {
    const res = await axios({
      method: 'GET',
      url: `pedidos/lista-pedidos-status/${_estabelecimento_id}`,
      params: { entregue },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'json',
    });
    return res.data;
  }
);

export const fetchPedidos = createAsyncThunk('pedidos/fetchAll', async (_estabelecimento_id) => {
  const res = await axios({
    method: 'GET',
    url: `pedidos/lista-pedidos/${_estabelecimento_id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'json',
  });
  return res.data;
});

export const fetchPedido = createAsyncThunk('pedidos/fetchOne', async (_pedido_id) => {
  const res = await axios({
    method: 'GET',
    url: `pedidos/lista-pedido/${_pedido_id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'json',
  });
  return res.data;
});

// eslint-disable-next-line consistent-return
export const entregarPedido = createAsyncThunk('pedidos/entregar', async ({ pedido_id, _pedido_entregue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const res = await axios.put(`/pedidos/entregar/${pedido_id}`, { _pedido_entregue }, config);
    return res.data;
  } catch (err) {
    // pick the errors
    const { errors } = err.response.data;
    console.log(errors);
  }
});

const initialState = {
  loading: 'idle',
  pedidosData: [],
  pedidosPedentes: [],
};

// SLICE
const pedidosSlice = createSlice({
  name: 'pedidos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPedidos.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPedidos.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.pedidosData = action.payload;
    });
    builder.addCase(fetchPedidosStatus.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPedidosStatus.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.pedidosPedentes = action.payload;
    });

    builder.addCase(entregarPedido.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(entregarPedido.fulfilled, (state, action) => {
      state.loading = 'idle';
      const pedido = action.payload;

      const updatePedido = state.pedidosPedentes.filter((_pedido) => _pedido.id !== pedido.id);

      state.pedidosPedentes = updatePedido;
    });
  },
});

// SELECTORS
const getPedidos = (state) => state.pedidos.pedidosData;
const getPedidosByStatus = (state) => state.pedidos.pedidosPedentes;
const loading = (state) => state.pedidos.loading;

export const getAllPedidos = createSelector([getPedidos], (pedidos) => pedidos);
export const getAllPedidosByStatus = createSelector([getPedidosByStatus], (pedidos) => pedidos);
export const getLoadingPedidos = createSelector([loading], (loading) => loading);

// // Export the reducer as a default export
export default pedidosSlice.reducer;
