// @mui
import { Stack, Link, Typography } from '@mui/material';

// config
import { VERSION } from '../../../config';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  return (
    <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <div>
        <Typography variant="body2" gutterBottom>
          <Typography variant="body2" component="span" sx={{ color: 'white' }}>
            Feito com S2
          </Typography>
          <Link underline="none" color="inherit">
            <Typography variant="body2" sx={{ color: 'white' }}>
              Versão {VERSION}
            </Typography>
          </Link>
        </Typography>
      </div>
    </Stack>
  );
}
