import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';

import IntlCurrencyInput from 'react-intl-currency-input';

// ----------------------------------------------------------------------

RHFTextFieldIntlCurrency.propTypes = {
  name: PropTypes.string,
  max: PropTypes.number,
};

export default function RHFTextFieldIntlCurrency({ name, max, ...rest }) {
  const { control } = useFormContext();

  const handleChange = (event) => {
    event.preventDefault();
  };

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, ref }) => (
        <IntlCurrencyInput
          currency="BRL"
          config={currencyConfig}
          max={max}
          onChange={handleChange}
          inputRef={ref}
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...rest}
        />
      )}
    />
  );
}
