// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <RtlLayout>
        <NotistackProvider>
          <ProgressBarStyle />
          <Settings />
          <ScrollToTop />
          <Router />
        </NotistackProvider>
      </RtlLayout>
    </ThemeProvider>
  );
}
