import { useEffect } from 'react';

// redux
import { useDispatch } from '../../redux/store';
import { getPlataforma } from '../../redux/slices/plataforma';

// ----------------------------------------------------------------------

export default function Settings() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlataforma());
  }, [dispatch]);

  return (
    <>
      {/*       <Link href={`https://api.whatsapp.com/send?phone=55${plataforma?.whatsapp}`}>
        <ToggleButton />
      </Link> */}
    </>
  );
}
