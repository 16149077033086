import { useEffect } from 'react';
// hooks
import { loadCurrentEstabelecimento } from '../redux/slices/estabelecimento';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// import { useSelector, useDispatch } from 'react-redux';
import { useDispatch, useSelector } from '../redux/store';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const dispatch = useDispatch();

  const estabelecimento = useSelector((state) => state.estabelecimento.currentEstabelecimento);

  useEffect(() => {
    dispatch(loadCurrentEstabelecimento());
  }, [dispatch]);

  return (
    <Avatar
      src={estabelecimento?.avatar_url}
      alt={estabelecimento?.nome}
      color={estabelecimento?.avatar_url ? 'default' : createAvatar(estabelecimento?.nome).color}
      {...other}
    >
      {createAvatar(estabelecimento?.nome).nome}
    </Avatar>
  );
}
