// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import Logo from '../../assets/images/logo.png';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    background: theme.palette.primary.main,
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  minHeight: 600,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 10),
  background: '#fff',
  borderRadius: 10,
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mb: 5 }}>
              <Box>
                <>
                  <Image disabledEffect src={Logo} sx={{ marginBottom: 5 }} />
                </>
                <Typography variant="h4" gutterBottom textAlign="center">
                  Login
                </Typography>
              </Box>
            </Stack>

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
