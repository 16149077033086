import PropTypes from 'prop-types';

import InputMask from 'react-input-mask';

// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextFieldMask.propTypes = {
  name: PropTypes.string,
  mask: PropTypes.string,
};

export default function RHFTextFieldMask({ name, mask, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputMask mask={mask} maskChar=" " {...field} fullWidth error={!!error} helperText={error?.message} {...other}>
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
      )}
    />
  );
}
