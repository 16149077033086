/* eslint-disable import/no-unresolved */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'esqueci-minha-senha', element: <ResetPassword /> },
        { path: 'recuperar-senha', element: <ForgotPassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard/home', element: <GeneralApp /> },
        { path: 'dashboard/produtos', element: <ProdutosList /> },
        { path: 'dashboard/produto/novo', element: <ProdutoCreate /> },
        { path: 'dashboard/produto/:id/editar', element: <ProdutoCreate /> },
        { path: 'dashboard/ofertas', element: <OfertasList /> },
        { path: 'dashboard/oferta/nova', element: <OfertaCreate /> },
        { path: 'dashboard/oferta/:id/editar', element: <OfertaCreate /> },
        { path: 'dashboard/historico/pedidos', element: <Historico /> },
        { path: 'dashboard/pedidos/:id/editar', element: <Pedido /> },
        { path: 'dashboard/clientes', element: <Clientes /> },
        { path: 'dashboard/cardapio', element: <Cardapio /> },
        { path: 'dashboard/carteira', element: <Carteira /> },
        { path: 'dashboard/terminal', element: <Terminal /> },
        { path: 'dashboard/configuracao', element: <Configuracao /> },

        {
          path: 'dashboard/user',
          children: [
            { element: <Navigate to="/dashboard/user/minha-conta" replace />, index: true },
            { path: 'minha-conta', element: <UserAccount /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'changelog', element: <Changelog /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/general-app')));
const OfertasList = Loadable(lazy(() => import('../pages/dashboard/ofertas-list')));
const OfertaCreate = Loadable(lazy(() => import('../pages/dashboard/oferta-create')));

const ProdutosList = Loadable(lazy(() => import('../pages/dashboard/produtos-list')));
const ProdutoCreate = Loadable(lazy(() => import('../pages/dashboard/produto-create')));

const Historico = Loadable(lazy(() => import('../pages/dashboard/historico')));
const Pedido = Loadable(lazy(() => import('../pages/dashboard/pedido-details')));
const Clientes = Loadable(lazy(() => import('../pages/dashboard/clientes')));
const Cardapio = Loadable(lazy(() => import('../pages/dashboard/cardapio')));
const Carteira = Loadable(lazy(() => import('../pages/dashboard/carteira')));
const Terminal = Loadable(lazy(() => import('../pages/dashboard/terminal')));
const Configuracao = Loadable(lazy(() => import('../pages/dashboard/configuracao')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/user-account')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/user-create')));
// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Changelog = Loadable(lazy(() => import('../pages/Changelog')));
