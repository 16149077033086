/* eslint-disable camelcase */
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import uniqBy from 'lodash/uniqBy';

// utils
// eslint-disable-next-line import/no-duplicates
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const accessToken = localStorage.getItem('@Famires:token');

// THUNKS
export const fetchOfertas = createAsyncThunk('ofertas/fetchAll', async (_estabelecimento_id) => {
  const res = await axios({
    method: 'get',
    url: `/auth/ofertas/lista-ofertas-estabelecimento/${_estabelecimento_id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'json',
  });
  return res.data;
});

export const createOferta = createAsyncThunk('ofertas/addOne', async (formData) => {
  const res = await axios({
    method: 'POST',
    url: '/auth/ofertas/cria-oferta',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  });
  return res.data;
});

export const updateOferta = createAsyncThunk('ofertas/update', async (oferta_id, formData) => {
  const res = await axios({
    method: 'PATCH',
    url: `/auth/ofertas/atualiza-oferta/${oferta_id}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  });
  return res.data;
});

export const updateOfertaStatus = createAsyncThunk('ofertas/updateStatus', async (oferta_id, oferta_ativa) => {
  const res = await axios({
    method: 'PATCH',
    url: `/auth/ofertas/oferta/ativar/${oferta_id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'json',
    data: { oferta_ativa },
  });
  return res.data;
});

const ofertasSlice = createSlice({
  name: 'ofertas',
  initialState: {
    loading: 'idle',
    ofertasData: [],
    currentOferta: null,
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchOfertas.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchOfertas.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.ofertasData = action.payload;
    });
    builder.addCase(createOferta.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(createOferta.fulfilled, (state, action) => {
      state.loading = 'idle';
      const oferta = action.payload;
      const isEmptyList = state.ofertasData.length === 0;

      if (isEmptyList) {
        state.ofertasData = [...state.ofertasData, oferta];
      } else {
        state.ofertasData = state.ofertasData.map((_oferta) => {
          const isExisted = _oferta.id === oferta.id;
          if (isExisted) {
            return {
              ..._oferta,
            };
          }
          return _oferta;
        });
      }
      state.ofertasData = uniqBy([...state.ofertasData, oferta], 'id');
    });
    builder.addCase(updateOferta.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(updateOferta.fulfilled, (state, action) => {
      const oferta = action.payload;
      const updateOferta = state.ofertasData.map((_oferta) => {
        if (_oferta.id === oferta.id) {
          return oferta;
        }
        return _oferta;
      });

      state.loading = 'idle';
      state.events = updateOferta;
    });
  },
});

// ----------------------------------------------------------------------

// SELECTORS
const getOfertas = (state) => state.ofertas.ofertasData;
const getOferta = (state) => state.ofertas.currentOferta;

export const loading = (state) => state.ofertas.loading;

export const getAllOfertas = createSelector([getOfertas], (ofertas) => ofertas);
export const getCurrentOfertas = createSelector([getOferta], (oferta) => oferta);
export const getLoadingOfertas = createSelector([loading], (loading) => loading);

// // Export the reducer as a default export
export default ofertasSlice.reducer;
