import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

// ----------------------------------------------------------------------
const accessToken = localStorage.getItem('@Famires:token');
// ----------------------------------------------------------------------

// THUNKS

// load current Estabelecimento
export const loadCurrentEstabelecimento = createAsyncThunk('estabelecimento/loadCurrentEstabelecimento', async () => {
  const res = await axios({
    method: 'GET',
    url: `/auth/estabelecimento/busca-perfil-estabelecimento`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return res.data;
});

// SLICE
const estabelecimentoSlice = createSlice({
  name: 'estabelecimento',
  initialState: {
    loading: 'idle',
    currentEstabelecimento: null,
    error: null,
  },
  reducers: {
    unloadCurrentEstabelecimento(state) {
      state.loading = 'idle';
      state.currentEstabelecimento = null;
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loadCurrentEstabelecimento.fulfilled]: (state, action) => {
      state.loading = 'done';
      state.currentEstabelecimento = action.payload;
    },
    [loadCurrentEstabelecimento.rejected]: (state, action) => {
      state.loading = 'fail';
      state.currentEstabelecimento = null;
      state.error = action.error;
    },
  },
});

// Extract and export each action creator by name
export const { unloadCurrentEstabelecimento } = estabelecimentoSlice.actions;

// // Export the reducer as a default export
export default estabelecimentoSlice.reducer;
