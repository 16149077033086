import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import configuracaoReducer from './slices/configuracao';
import plataformaReducer from './slices/plataforma';
import ofertasReducer from './slices/ofertas';
import produtosReducer from './slices/produtos';
import estabelecimentoReducer from './slices/estabelecimento';
import pedidosReducer from './slices/pedidos';
import carteiraReducer from './slices/carteira';
import usersReducer from './slices/usersSlice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const estabelecimentoPersistConfig = {
  key: 'estabelecimento',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['estabelecimento'],
};

const rootReducer = combineReducers({
  users: usersReducer,
  configuracao: configuracaoReducer,
  plataforma: plataformaReducer,
  ofertas: ofertasReducer,
  produtos: produtosReducer,
  pedidos: pedidosReducer,
  carteira: carteiraReducer,
  estabelecimento: persistReducer(estabelecimentoPersistConfig, estabelecimentoReducer),
});

export { rootPersistConfig, rootReducer };
