// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_home'),
  user: getIcon('ic_user'),
  history: getIcon('ic_history'),
  group: getIcon('ic_group'),
  menu: getIcon('ic_knife_and_fork'),
  wallet: getIcon('ic_wallet'),
  document: getIcon('ic_settings'),
  settings: getIcon('ic_settings'),
  terminal: getIcon('ic_terminal'),
  ticket: getIcon('ic_price_ticket'),
  service: getIcon('ic_bookmark_service'),
  product: getIcon('ic_product'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'início', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard, disabled: false },
      {
        title: 'gerenciar produtos',
        path: PATH_DASHBOARD.general.produto.root,
        icon: ICONS.product,
        disabled: false,
        children: [{ title: 'Produtos', path: PATH_DASHBOARD.general.produto.list, disabled: false }],
      },
      {
        title: 'gerenciar ofertas',
        path: PATH_DASHBOARD.general.oferta.root,
        icon: ICONS.ticket,
        disabled: false,
        children: [{ title: 'oferta simples', path: PATH_DASHBOARD.general.oferta.list, disabled: false }],
      },
      {
        title: 'histórico',
        path: PATH_DASHBOARD.general.historico.root,
        icon: ICONS.history,
        disabled: false,
      },
      {
        title: 'Seus clientes fiéis',
        path: PATH_DASHBOARD.general.clientes,
        icon: ICONS.group,
        disabled: true,
      },
      { title: 'produtos & serviços', path: PATH_DASHBOARD.general.cardapio, icon: ICONS.service, disabled: true },
      { title: 'carteira', path: PATH_DASHBOARD.general.carteira, icon: ICONS.wallet, disabled: false },
    ],
  },
];

export default navConfig;
