// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/esqueci-minha-senha'),
  forgotPassword: path(ROOTS_AUTH, '/recuperar-senha'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  changelog: '/changelog',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/home'),
    produto: {
      root: path(ROOTS_DASHBOARD, '/produto'),
      list: path(ROOTS_DASHBOARD, '/produtos'),
      novoProduto: path(ROOTS_DASHBOARD, '/produto/novo'),
      editById: path(ROOTS_DASHBOARD, `/produto/reece-chung/editar`),
    },
    oferta: {
      root: path(ROOTS_DASHBOARD, '/oferta'),
      list: path(ROOTS_DASHBOARD, '/ofertas'),
      novaPromocao: path(ROOTS_DASHBOARD, '/oferta/nova'),
      editById: path(ROOTS_DASHBOARD, `/oferta/reece-chung/editar`),
    },
    historico: {
      root: path(ROOTS_DASHBOARD, '/historico/pedidos'),
      list: path(ROOTS_DASHBOARD, '/historico'),
      details: path(ROOTS_DASHBOARD, `/pedidos`),
    },
    clientes: path(ROOTS_DASHBOARD, '/clientes'),
    cardapio: path(ROOTS_DASHBOARD, '/cardapio'),
    carteira: path(ROOTS_DASHBOARD, '/carteira'),
    terminal: path(ROOTS_DASHBOARD, '/terminal'),
    configuracao: path(ROOTS_DASHBOARD, '/configuracao'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/minha-conta'),
  },
};

export const PATH_DOCS = '';
