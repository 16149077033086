/* eslint-disable camelcase */
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
// utils
// eslint-disable-next-line import/named, import/no-duplicates
import axios from '../../utils/axios';
// ----------------------------------------------------------------------

const accessToken = localStorage.getItem('@Famires:token');

// THUNKS
export const fetchProdutos = createAsyncThunk('produtos/fetchAll', async () => {
  const res = await axios({
    method: 'get',
    url: `/auth/produtos/lista-produtos`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'json',
  });
  return res.data;
});

export const createProduto = createAsyncThunk('produtos/CreateOne', async (formData) => {
  const res = await axios({
    method: 'post',
    url: `/auth/produtos/cria-produto`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  });
  return res.data;
});

export const updateProduto = createAsyncThunk('produtos/Update', async ({ produto_id, formData }) => {
  console.log('formData: ', formData);
  const res = await axios({
    method: 'PATCH',
    url: `/auth/produtos/atualiza-produto/${produto_id}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  });
  return res.data;
});

const initialState = {
  loading: 'idle',
  produtosData: [],
  currentProduto: null,
  error: null,
};

const produtosSlice = createSlice({
  name: 'produtos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProdutos.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchProdutos.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.produtosData = action.payload;
    });
    builder.addCase(createProduto.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(createProduto.fulfilled, (state, action) => {
      state.loading = 'idle';
      const newProduto = action.payload;
      state.produtosData = [...state.produtosData, newProduto];
    });
    builder.addCase(updateProduto.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(updateProduto.fulfilled, (state, action) => {
      state.loading = 'idle';
      const produto = action.payload;
      const updateProduto = state.produtosData.map((_produto) => {
        if (_produto === produto.id) {
          return produto;
        }
        return _produto;
      });
      state.produtosData = updateProduto;
    });
  },
});

// ----------------------------------------------------------------------

// SELECTORS
const getProdutos = (state) => state.produtos.produtosData;
const getProduto = (state) => state.produtos.currentProduto;
const loading = (state) => state.pedidos.loading;

export const getAllProdutos = createSelector([getProdutos], (produtos) => produtos);
export const getCurrentProduto = createSelector([getProduto], (produto) => produto);
export const getLoadingProdutos = createSelector([loading], (loading) => loading);

// // Export the reducer as a default export
export default produtosSlice.reducer;
