import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

// utils
import axios from '../../utils/axios';
// ----------------------------------------------------------------------

const initialState = {
  loading: 'idle',
  currentCarteira: null,
  transacoes: [],
  error: null,
};

export const fetchCarteira = createAsyncThunk('carteira/fetchCarteira', async (userId) => {
  const accessToken = localStorage.getItem('@Famires:token');

  const res = await axios({
    method: 'GET',
    url: `/carteira/lista-carteira/user/${userId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'json',
  });
  return res.data;
});

export const fetchAlltransacoes = createAsyncThunk('carteira/fetchAlltransacoes', async (userId) => {
  const accessToken = localStorage.getItem('@Famires:token');

  const res = await axios({
    method: 'GET',
    url: `/carteira/transacao/lista-transacoes/user/${userId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'json',
  });
  return res.data;
});

export const requestSaque = createAsyncThunk('carteira/transacao/request', async (formData) => {
  const accessToken = localStorage.getItem('@Famires:token');

  const res = await axios({
    method: 'POST',
    url: '/carteira/transacao/saque-transacao',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  });
  return res.data;
});

const carteiraSlice = createSlice({
  name: 'carteira',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCarteira.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchCarteira.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.currentCarteira = action.payload;
    });
    builder.addCase(fetchAlltransacoes.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchAlltransacoes.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.transacoes = action.payload;
    });
    builder.addCase(requestSaque.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(requestSaque.fulfilled, (state, action) => {
      state.loading = 'idle';
      const transacao = action.payload;
      const isEmptyList = state.transacoes.length === 0;

      if (isEmptyList) {
        state.transacoes = [...state.ofertasData, transacao];
      } else {
        state.transacoes = state.transacoes.map((_transacao) => {
          const isExisted = _transacao.id === transacao.id;
          if (isExisted) {
            return {
              ..._transacao,
            };
          }
          return _transacao;
        });
      }
      state.ofertasData = uniqBy([...state.ofertasData, transacao], 'id');
    });
  },
});

// SELECTORS
const getCarteira = (state) => state.carteira.currentCarteira;

const getTransacoes = (state) => state.carteira.transacoes;

const loading = (state) => state.carteira.loading;

export const getCurrentCarteira = createSelector([getCarteira], (currentCarteira) => currentCarteira);
export const getAllTransacoes = createSelector([getTransacoes], (transacoes) => transacoes);
export const getLoadingCarteira = createSelector([loading], (loading) => loading);

// Reducer
export default carteiraSlice.reducer;

// ----------------------------------------------------------------------
