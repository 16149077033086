import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: 'idle',
  currentConfiguracao: null,
  error: null,
};

export const fetchConfiguracao = createAsyncThunk('configuracao/fetchConfiguracao', async () => {
  const res = await axios({
    method: 'GET',
    url: `/auth/estabelecimento/busca-config-estabelecimento`,
    responseType: 'json',
  });
  return res.data;
});

const configuracaoSlice = createSlice({
  name: 'configuracao',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // GET CONFIGURACAO
    getConfiguracaoSuccess(state, action) {
      state.loading = false;
      state.configuracao = action.payload;
    },
  },

  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchConfiguracao.fulfilled]: (state, action) => {
      state.loading = 'done';
      state.currentConfiguracao = action.payload;
    },
    [fetchConfiguracao.rejected]: (state, action) => {
      state.loading = 'fail';
      state.error = action.payload;
    },
  },
});

// SELECTORS
const selectConfiguracao = (state) => state.configuracao.currentConfiguracao;

export const selectCurrentConfiguracao = (state) => state.configuracao.currentConfiguracao;

export const selectConfiguracaoLoading = (state) => state.configuracao.loading;

export const selectCurrentUser = createSelector([selectConfiguracao], (currentConfiguracao) => currentConfiguracao);

// Reducer
export default configuracaoSlice.reducer;

// ----------------------------------------------------------------------
